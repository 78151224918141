@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.video-details-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  max-width: 1120px;
  position: relative;
  top: -220px;
  margin-bottom: -150px;
  z-index: 6; // Header is 5

  @include breakpoint-down('sm') {
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);
  }

  @include breakpoint-up('md') {
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-3);
  }
}

.video-details-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 16px;

  @include breakpoint-down('xs') {
    flex-direction: column;
  }

  span {
    font-size: 28px;
    @include breakpoint-up('md') {
      font-size: 32px;
    }
    @include breakpoint-up('lg') {
      font-size: 48px;
    }
  }
}

.copy-link-button {
  margin-bottom: 24px !important;
}

.display-text {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-1);
  align-items: center;
  justify-content: center;
}

.share-button {
  position: relative;

  @include breakpoint-down('xs') {
    width: 100%;
    text-align: center;
  }

    .copy-link-button {
      background-color: transparent;
      border: 1px solid rgba(226, 226, 226, 1);
      border-radius: 40px;
      padding: var(--spacing-1) var(--spacing-3);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s ease;
      font-family: var(--font-family-default);
      font-weight: var(--font-weight-light);
      font-size: 18px;
      letter-spacing: 0.9px;
      color: var(--white);
      text-align: center;
      width: 121px;
      height: 38px;
    
      svg {
        fill: white;
      }
    
      &:hover {
        background: linear-gradient(248.24deg, #FFFFFF 17.66%, #C6C6C6 71.37%);
        color: rgba(0, 0, 0, 1);
    
        svg {
          fill: black;
        }
      }
    
      @include breakpoint-down('xs') {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
    }

  .tooltip-text {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: rgba(0, 0, 0, 1);
    padding: 12px 20px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: var(--font-weight-light);
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease, visibility 0.3s ease;
    margin-bottom: 20px;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  .tooltip-text::after {
    //The point of the tooltip
    content: '';
    position: absolute;
    left: calc(50% - 8.5px);
    top: 100%;
    transform: rotate(180deg);
    border-width: 8.5px;
    border-style: solid;
    border-color: transparent transparent var(--white) transparent;
  }
}
