@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.hero-full-screen {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  .hero-full-screen__inner {
    position: absolute;
    left: 0;
    width: 100%;

    @include breakpoint-up(md) {
      display: flex;
      align-items: center;
      height: 100vh;
    }
  }

  .content {
    text-align: center;

    @include breakpoint-up(sm) {
      margin-top: var(--spacing-2);
    }

    @include breakpoint-up(md) {
      width: 450px;
      max-width: 100%;
      padding: 0 var(--spacing-1);
      margin-top: 0;
      text-align: left;
    }

    @include breakpoint-up(lg) {
      width: 550px;
    }

    @include breakpoint-up(xl) {
      width: 50%;
      padding-left: 10%;
    }

    &.content--title-frame {
      h2 {
        @include breakpoint-up(lg) {
          font-size: 2vmax;
        }
      }
    }

    &.content--other-frame {
      .headings {
        margin: var(--spacing-12) var(--spacing-2) 0;

        @include breakpoint-up(md) {
          padding: var(--spacing-3) 0;
          margin-top: var(--spacing-12) 0;
        }

        @include breakpoint-down(xs) {
          h2 {
            margin-bottom: 0;
          }

          h3 {
            margin-top: 0;
          }
        }
      }
    }

    .ctas {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .button {
        margin-top: var(--spacing-1);
        display: flex;
        gap: var(--spacing-2);
        flex-direction: column;

        @include breakpoint-up(md) {
          margin-top: var(--spacing-3);
          flex-direction: row;
        }
      }

      .phone {
        font-size: 16px;
      }

      .email {
        font-size: 12px;
      }

      a {
        @include link(white);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 8px;
        font-weight: var(--font-weight-extra-bold);
        font-size: var(--font-size-body-xsm);

        @include breakpoint-up(sm) {
          font-size: var(--font-size-body-md);
        }
      }

      @include breakpoint-up(md) {
        display: block;

        .email,
        .phone {
          font-size: 20px;
        }
      }
    }

    .cta-icon {
      margin-right: 8px;
      height: 36px;
      width: 36px;

      @include breakpoint-up(md) {
        margin-right: 16px;
      }
    }

    h1 {
      margin-bottom: var(--spacing-2);

      @include breakpoint-up(md) {
        margin-bottom: var(--spacing-3);
      }

      @include breakpoint-up(lg) {
        font-size: 5vmax;
      }
    }

    h2 {
      margin-bottom: var(--spacing-3);

      @include breakpoint-up(md) {
        margin-bottom: var(--spacing-4);
      }
    }
  }

  .hero-full-screen__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 36%;
    width: 100%;
    background: linear-gradient(180deg, var(--black) 0, var(--black) 56%, rgba(var(--black-rgba), 0) 100%);

    @include breakpoint-up(md) {
      width: 100%;
      height: 100%;
      background: linear-gradient(60deg, var(--black) 0, var(--black) 10%, rgba(var(--black-rgba), 0) 75%);
    }
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;

    @include breakpoint-up(md) {
      object-position: 60% center;
      margin-top: 0px;
    }
  }
}
