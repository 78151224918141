/* @TODO: finish removing camel case from css vars to allow for cleaner mixins. */
html {
  /* colors */
  --black: #000000;
  --black-rgba: 0, 0, 0;

  --turquoise-100: #dafbff;
  --turquoise-200: #b4fbff;
  --turquoise-300: #20ffff;
  --turquoise-400: #06eaff;
  --turquoise-500: #05c3dd;
  --turquoise-600: #049cb1;
  --turquoise-700: #037d8d;
  --turquoise-800: #036471;
  --turquoise-900: #02505b;

  --blue-lagoon-100: #005670;
  --blue-lagoon-200: #8ae4ff;
  --blue-lagoon-300: #00bdf6;
  --blue-lagoon-400: #007092;
  --blue-lagoon-500: #005670;
  --blue-lagoon-600: #003c4e;
  --blue-lagoon-700: #002a37;
  --blue-lagoon-800: #001d26;
  --blue-lagoon-900: #00151b;

  --gray-100: #e2e2e2;
  --gray-200: #c6c6c6;
  --gray-300: #ababab;
  --gray-400: #919191;
  --gray-500: #757575;
  --gray-600: #5e5e5e;
  --gray-700: #474747;
  --gray-800: #303030;
  --gray-900: #1b1b1b;

  --gray-900-rgb: 27, 27, 27;

  --gold: #c9a045;
  --white: #ffffff;
  --white-rgba: 255, 255, 255;

  // AKA "Art" according to design system
  --brand-primary: var(--turquoise-500);
  --brand-primary-100: var(--turquoise-100);
  --brand-primary-200: var(--turquoise-200);
  --brand-primary-300: var(--turquoise-300);
  --brand-primary-400: var(--turquoise-400);
  --brand-primary-500: var(--turquoise-500);
  --brand-primary-600: var(--turquoise-600);
  --brand-primary-700: var(--turquoise-700);
  --brand-primary-800: var(--turquoise-800);
  --brand-primary-900: var(--turquoise-900);

  // AKA "Science" according to design system
  --brand-secondary: var(--blue-lagoon-500);
  --brand-secondary-100: var(--blue-lagoon-100);
  --brand-secondary-200: var(--blue-lagoon-200);
  --brand-secondary-300: var(--blue-lagoon-300);
  --brand-secondary-400: var(--blue-lagoon-400);
  --brand-secondary-500: var(--blue-lagoon-500);
  --brand-secondary-600: var(--blue-lagoon-600);
  --brand-secondary-700: var(--blue-lagoon-700);
  --brand-secondary-800: var(--blue-lagoon-800);
  --brand-secondary-900: var(--blue-lagoon-900);

  --brand-accent: var(--gold);

  /* Gradient colors, more cleanup necessary */
  --header-bg-color: linear-gradient(180deg, var(--black) 70.3%, rgba(var(--black-rgba), 0) 100%);
  --header-subnav-bg-color: radial-gradient(96.81% 96.81% at 50% 50%, rgba(var(--white-rgba), 0.1) 0%, rgba(var(--white-rgba), 0) 100%);
  --hero-fade-bottom-color: linear-gradient(180deg, rgba(var(--black-rgba), 0) 6.83%, var(--black) 74.06%);
  --box-shadow-glow: 0px 0px 100px rgba(var(--white-rgba), 0.3);
  --box-shadow-btn: 0px 0px 8px rgba(var(--black-rgba), 0.5);

  /* Typography */
  --font-family-default: 'graphie', sans-serif;
  --font-family-headings: var(--font-family-default);

  --font-size-h1-mobile: 32px;
  --font-size-h1-desktop: 48px;

  --font-size-h2-mobile: 24px;
  --font-size-h2-desktop: 28px;

  --font-size-h3-mobile: 20px;
  --font-size-h3-desktop: 22px;

  --font-size-h4-mobile: 18px;
  --font-size-h4-desktop: 20px;

  --font-size-h5-mobile: 16px;
  --font-size-h5-desktop: 18px;

  --font-size-h6-mobile: 15px;
  --font-size-h6-desktop: 16px;

  // Specialty font styles
  --font-size-specialty-display-mobile: 48px;
  --font-size-specialty-display-desktop: 64px;

  --font-size-specialty-label-mobile: 14px;
  --font-size-specialty-label-desktop: 16px;

  --font-size-specialty-form-inputs-desktop: 18px;

  --font-size-body-lg: 20px;
  --font-size-body-md: 16px;
  --font-size-body-sm: 14px;
  --font-size-body-xsm: 12px;

  --font-size-body: var(--font-size-body-md);

  /* Font weights */
  --font-weight-ultra-light: 200;
  --font-weight-light: 350; // 300 does not work
  --font-weight-semi-bold: 400;
  --font-weight-bold: 500;
  --font-weight-extra-bold: 600;

  --font-weight-body: var(--font-weight-light);

  --line-height-body: 1.5;

  /* Element colors */
  --body-bg-color: var(--black);
  --body-color: var(--white);
  --close-button-width-desktop: 24px;
  --close-button-width-mobile: 24px;

  /* Link */
  --link-color-transition: all 0.2s linear;
  --link-color-black-hover: var(--gray-800);
  --link-color-primary-hover: var(--brand-primary-200);
  --link-color-gray-500-hover: var(--gray-600);
  --link-color-gray-300-hover: var(--gray-400);
  --link-color-gray-100-hover: var(--white);
  --link-color-white-hover: var(--gray-100);

  /* Margin values */
  --spacing-0: 0px;
  --spacing-1: 8px;
  --spacing-2: 16px;
  --spacing-3: 24px;
  --spacing-4: 32px;
  --spacing-5: 40px;
  --spacing-6: 48px;
  --spacing-7: 56px;
  --spacing-8: 64px;
  --spacing-9: 72px;
  --spacing-10: 80px;
  --spacing-11: 88px;
  --spacing-12: 96px;

  --header-brand-logo-width-desktop: 313px;
  --header-brand-logo-height-desktop: 31px;
  --header-brand-logo-width-mobile: 163px;
  --header-brand-logo-height-mobile: 20px;
  --header-height-desktop: 72px;
  --header-height-mobile: 72px;
  --header-submenu-height-desktop: 50px;
  --footer-logo-width: 120px;
  --footer-logo-height: 30px;

  --footer-margin-top: 80px;

  /* Revisit the zIndex values */
  --z-index-1: 1;
  --z-index-100: 100;
  --z-index-101: 101;
  --z-index-102: 102;
}
